<!--
  Displays a single collection in BLKDRP Distro view
  A collection has associated claims, and displays information like the number of packs / tokens in the collection.
-->
<template>
  <div class="item-view">
    <ItemHeader
      :data="targetCollection"
      backLabel="Distro Home"
      @back="$router.push('/distro')"
    />
    <div class="bottom">
      <div class="tabs">
        <h4
          :class="{ active: shown == 'types' }"
          @click="shown = 'types'"
        >
          Types
        </h4>
        <h4
          :class="{ active: shown == 'tokens' }"
          @click="initialLoad"
        >
          Tokens
        </h4>
      </div>

      <div class="asset-tables">
        <div
          v-if="shown === 'types'"
          class="table-container"
        >
          <TokenTable
            v-if="collectionTypesFormatted"
            :data="collectionTypesFormatted"
            :additionalHeaders="typeHeaders"
            actionText="View Claims"
            @view="viewToken"
          />
        </div>
        <div
          v-if="shown === 'tokens'"
          class="table-container"
        >
          <TokenTable
            v-if="tokensFormatted"
            :data="tokensFormatted"
            :additionalHeaders="tokenHeaders"
            hideActionButton
            @updatePage="updatePage"
            @updateItemsPerPage="updateItemsPerPage"
            :itemsPerPageOptions="paginationOptions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, getDoc } from 'firebase/firestore'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  async created () {
    this.setLoading(true)
    this.claimsMetadataListener()
    this.claimsByCollectionListener(this.$route.params.collection)
    const res = await getDoc(doc(this.$fb.db, 'token-contracts', this.$route.params.collection))
    this.targetCollection = res.data().metadata
    await this.getTokenContractTypes({ contract: this.$route.params.collection, mode: 'all' })
    this.setLoading(false)
  },
  data () {
    return {
      targetCollection: null,
      shown: 'types',
      tokens: null,
      tokensPerLoad: 50,
      tokensPerPage: 10,
      paginationToken: 0,
      loadedAll: false,
      typeHeaders: [
        {
          text: 'Active',
          value: 'active'
        },
        {
          text: 'Feat',
          value: 'featured'
        },
        {
          text: 'Cap',
          value: 'cap'
        },
        {
          text: 'Claims',
          value: 'totalClaims'
        },
        {
          text: 'Claimed',
          value: 'claimed'
        },
        {
          text: 'Market',
          value: 'onMarket'
        }
      ],
      tokenHeaders: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Owner',
          value: 'owner.name'
        },
        {
          text: 'Issued',
          value: 'issued'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'targetCollectionTypes',
      'claimsByCollection',
      'marketDataByType',
      'claimsMetadata'
    ]),

    collectionTypesFormatted () {
      if (this.targetCollectionTypes) {
        const formatted = []
        this.targetCollectionTypes.forEach((tokenType) => {
          const typeMarketData = this.marketDataByType(this.$route.params.collection, tokenType.id)
          const targetClaimsData = this.claimsByCollection[tokenType.id] || null
          const totalClaims = this.claimsMetadata ? this.claimsMetadata[tokenType.id] : 0
          tokenType.image = this.tokenImagePath(
            this.$route.params.collection,
            tokenType.id,
            80,
            true
          )
          tokenType.onMarket = typeMarketData ? typeMarketData.onMarket : 0
          tokenType.totalClaims = totalClaims || 0
          tokenType.claimed = targetClaimsData ? targetClaimsData.claimed : 0
          formatted.push(tokenType)
        })
        return formatted
      } else {
        return []
      }
    },

    tokensFormatted () {
      if (this.tokens) {
        const formatted = []
        this.tokens.forEach((token) => {
          token.name = token.metadata.title
          token.image = this.tokenImagePath(
            this.$route.params.collection,
            this.parseTokenType(token.id),
            80,
            true
          )
          token.issued = this.$moment.unix(token.metadata.issued_at / 1000).format('MM.DD.yyyy, hh:mm')
          formatted.push(token)
        })
        return formatted
      } else {
        return []
      }
    },

    paginationOptions () {
      const options = [5, 10, 15, -1]
      const output = []
      options.forEach((opt) => {
        if (this.tokens && this.tokens.length > opt && opt !== -1) {
          output.push(opt)
        } else if (opt === -1 && this.loadedAll) {
          output.push(opt)
        }
      })
      return output
    }
  },

  methods: {
    ...mapMutations([
      'setLoading'
    ]),

    ...mapActions([
      'getTokenContractTypes',
      'claimsByCollectionListener',
      'claimsMetadataListener',
      'getUserByNearId'
    ]),

    async initialLoad () {
      this.shown = 'tokens'
      if (!this.tokens) {
        const tokens = await this.loadTokens(0)
        this.tokens = await this.formatTokens(tokens)
      }
    },

    async loadTokens (token = 0) {
      this.setLoading(true)
      const res = await this.fbCall('getAllTokens', {
        contract: this.$route.params.collection,
        limit: this.tokensPerLoad,
        paginationToken: token
      })
      this.paginationToken = res.data.paginationToken
      if (!this.paginationToken) this.loadedAll = true
      return res.data.items
    },

    async formatTokens (tokens) {
      const formatted = []
      for (const i in tokens) {
        const targetToken = tokens[i]
        const user = await this.getUserByNearId(targetToken.ownerAddress)
        targetToken.owner = user
        formatted.push(targetToken)
      }
      this.setLoading(false)
      return formatted
    },

    async loadNewTokens () {
      if (this.paginationToken) {
        this.setLoading(true)
        const newTokens = await this.loadTokens(this.paginationToken)
        if (newTokens && newTokens.length) {
          const formatted = await this.formatTokens(newTokens)
          this.tokens = this.tokens.concat(formatted)
        }
      }
      this.setLoading(false)
    },

    /**
     * Loads more tokens on demand as the user pages through the table.
     */
    async updatePage (page) {
      if (this.tokens.length <= this.tokensPerPage * page) {
        await this.loadNewTokens()
      }
    },

    async updateItemsPerPage (items) {
      this.tokensPerPage = items
      if (this.tokens && items >= this.tokens.length) {
        await this.loadNewTokens()
      }
    },

    viewToken (token) {
      this.$router.push(`${this.$route.params.collection}/${token.id}`)
    }
  }
}
</script>
